import React from 'react';
import './zdjecia.css';

function Kuchnia() {
        return (
            <div className='div-zdjecia'>

   <div className='grid'>

    <div class="row"> 
  <div class="column">
    <img src="images/kuchnia/IMG_3126.jpg" style={{width:"100%"}}></img>
    <img src="images/kuchnia/IMG_3131.jpg" style={{width:"100%"}}></img>
   
   
  </div>
  <div class="column">
  <img src="images/kuchnia/IMG_3153.jpg" style={{width:"100%"}}></img>
  <img src="images/kuchnia/IMG_3154.jpg" style={{width:"100%"}}></img>
 
  </div>  
  <div class="column">
  <img src="images/kuchnia/IMG_3219.jpg" style={{width:"100%"}}></img>
    <img src="images/kuchnia/IMG_3225.jpg" style={{width:"100%"}}></img>
 
   
  </div>
  <div class="column">
    <img src="images/kuchnia/IMG_3302.jpg" style={{width:"100%"}}></img> 
    <img src="images/kuchnia/IMG_3144.jpg" style={{width:"100%"}}></img>
  </div> 
</div>
    </div>
        </div>

        );
    }
    
    export default Kuchnia;